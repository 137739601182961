<template>
    <NebulaPageWrapper
        class="user-management-container"
        padded contained
    >
        <NebulaGridRow>
            <NebulaGridBlock :columns="12">
                <UserManagementBanners :data="errors" />
                <PageWrapper>
                    <template #breadcrumbs>
                        <NebulaBreadcrumbs
                            :aria-label="$t('breadcrumbs-aria', { ns: 'usermanagement' })"
                            :breadcrumbs="breadcrumbs"
                            dropdownUseRouterLinks
                            showDimText
                        />
                    </template>
                    <template #heading>
                        <PageHeader>
                            <template #title>{{ $t('user-management-title', { ns: 'usermanagement' }) }}</template>
                        </PageHeader>
                    </template>
                    <template #body>
                        <NebulaTitleBlock
                            class="user-management__title"
                            title="Add Users"
                            :description="`Get started by inviting Connectors (or members for professional
                                organizations) to join your organization's account.`"
                            type="section"
                        />
                        <InviteInput
                            class="user-management__input"
                            :inputGroupData="formData.invite"
                            :updateErrors="updateErrors"
                            :stagedFromParent="invites"
                            @stage-invites="stageInvites"
                        />
                        <MemberTable :headers="['Email', 'Name', 'Status', 'Access Level', '']">
                            <tr v-for="(member, idx) in employees" :key="idx" class="nebula-table__row">
                                <NebulaTableCell responsiveLabel="Email" class="nebula-table__cell">
                                    <template #tableCell>
                                        {{ member.email }}
                                    </template>
                                </NebulaTableCell>
                                <NebulaTableCell
                                    responsiveLabel="Name"
                                    class="nebula-table__cell user-management__name-cell user-management__table-cell"
                                >
                                    <template #tableCell>
                                        <ProfileAvatar class="user-management__member-avatar" :image="member.avatarUrl" size="xs" />
                                        <div>
                                            <p class="user-management__member-name">{{ member.firstName }} {{ member.lastName }}</p>
                                            <p class="user-management__member-title">{{ member.title }}</p>
                                        </div>
                                    </template>
                                </NebulaTableCell>
                                <NebulaTableCell responsiveLabel="Status" class="nebula-table__cell">
                                    <template #tableCell>
                                        <NebulaBadge size="s" :color="badgeColor(member.isActive)">{{ activeText(member.isActive) }}</NebulaBadge>
                                    </template>
                                </NebulaTableCell>
                                <NebulaTableCell responsiveLabel="Access Level" class="nebula-table__cell">
                                    <template #tableCell>
                                        <div class="user-management__access-cell">
                                            <NebulaButton
                                                v-bind="accessButtonProps('member', member)"
                                                @click="demoteAdminToMember(member)"
                                                text="Member"
                                            />
                                            <NebulaButton
                                                v-bind="accessButtonProps('admin', member)"
                                                @click="promoteMemberToAdmin(member)"
                                                text="Admin"
                                            />
                                        </div>
                                    </template>
                                </NebulaTableCell>
                                <NebulaTableCell class="nebula-table__cell user-management__remove-cell">
                                    <template #tableCell>
                                        <NebulaButton
                                            class="user-management__remove-button"
                                            type="flat"
                                            icon-left="user-minus"
                                            text="Remove User"
                                            size="s"
                                            @click="confirmRemoveUser(member)"
                                        />
                                    </template>
                                </NebulaTableCell>
                            </tr>
                        </MemberTable>
                    </template>
                </PageWrapper>
            </NebulaGridBlock>
        </NebulaGridRow>
    </NebulaPageWrapper>
</template>

<script>
import createPayload from '@/mixins/data/createPayload';
import { INVITE_MAX } from '@/constants';

import {
    NebulaBreadcrumbs,
    NebulaGrid,
    NebulaTitleBlock,
    NebulaTableCell,
    NebulaBadge,
    NebulaButton,
} from '@discoveryedu/nebula-components';
import PageWrapper from '@/components/shared/PageWrapper.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import InviteInput from '@/components/shared/forms/InviteInput.vue';
import MemberTable from '@/components/shared/MemberTable.vue';
import Banners from '@/components/UserManagement/Banners.vue';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';

// import PageSection from '@/components/shared/layout/PageSection.vue';

export default {
    name: 'EmployerUserManagement',
    components: {
        NebulaBreadcrumbs,
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        PageWrapper,
        PageHeader,
        NebulaTitleBlock,
        UserManagementBanners: Banners,
        InviteInput,
        NebulaTableCell,
        NebulaBadge,
        MemberTable,
        NebulaButton,
        ProfileAvatar,
        // PageSection,
    },
    mixins: [createPayload],
    computed: {
        employeesLoading() {
            return this.$store.state.status.list_employees.loading;
        },
        employees() {
            return this.alphaSort(this.removeSelf(this.$store.state.app.employees), 'email');
        },
    },
    data() {
        return {
            breadcrumbs: [
                {
                    dim_text: '',
                    link: '/',
                    text: 'Dashboard',
                },
                {
                    dim_text: '',
                    link: '/employer-user-management',
                    text: 'User Management',
                },
            ],
            formData: {
                invite: {
                    value: '',
                },
            },
            errors: {
                invalid: [],
                duplicate: [],
                limit: [],
            },
            invites: [],
        };
    },
    methods: {
        accessButtonClass(type, member) {
            if (type === 'admin' && member.isAdmin) {
                return 'user-management__access-button--disabled';
            }

            return null;
        },
        accessButtonProps(type, member) {
            const memberType = member.isAdmin ? 'admin' : 'member';
            if (type === memberType) {
                return {
                    type: 'fill',
                    isDisabled: true,
                    class: 'user-management__access-button--disabled',
                };
            }
            return {
                type: 'flat',
                class: 'user-management__access-button--active',
            };
        },
        confirmRemoveUser(employee) {
            this.$store.dispatch('toggleModal', {
                category: 'user_management',
                type: 'remove',
                show: !this.$store.getters.removeUserModal.show,
                email: employee.email,
                employeeId: employee.employeeId,
            });
        },
        updateErrors(key, valueToAdd) {
            this.errors[key].push(valueToAdd);
        },
        // when invites emit from the InviteInput, update the local data object
        stageInvites(invites) {
            this.invites = invites;
            this.formData.invite.value = '';
        },
        badgeColor(isActive) {
            return isActive ? 'green' : 'light-gray';
        },
        activeText(isActive) {
            return isActive ? 'Active' : 'Inactive';
        },
        async promoteMemberToAdmin(member) {
            this.updateAccessLevel(member, 'admin');
        },
        async demoteAdminToMember(member) {
            this.updateAccessLevel(member, 'member');
        },
        async updateAccessLevel(member, role) {
            const { email, employeeId } = member;
            const payload = await this.createPayload({
                email,
                employeeId,
                isAdmin: Boolean(role === 'admin'),
            });
            await this.$store.dispatch('updateOrganizationEmployee', payload);
        },
        removeSelf(arrayOfObjects) {
            return arrayOfObjects.filter((each) => each.email !== this.$store.state.user.data.email);
        },
        alphaSort(arrayOfObjects, key) {
            // with pagination this should be handled on the backend
            // in the meantime enforce a basic sort to improve the UX
            function compare(a, b) {
                if (a[key] < b[key]) {
                    return -1;
                }
                if (a[key] > b[key]) {
                    return 1;
                }
                return 0;
            }
            return arrayOfObjects.sort(compare);
        },
    },
    watch: {
        async invites(updated) {
            if (updated && updated.length > 0) {
                const payload = await this.createPayload(this.invites);
                if (updated.length > INVITE_MAX) {
                    await this.updateErrors('limit', updated.length);
                    return;
                }
                await this.$store.dispatch('createOrganizationEmployees', payload);

                await this.$store.dispatch('getOrganizationEmployees', payload);
                this.invites = [];
            }
        },
    },
    async mounted() {
        const payload = await this.createPayload();
        this.$store.dispatch('getOrganizationEmployees', payload);
    },
};
</script>
<style lang='stylus'>
:root {
    --nebula-action-bar-background-color: transparent;
}

tableCell() {
    padding-block: 12px;
    padding-inline: 12px;
}

.user-management {
    &__access-cell {
        display: flex;
    }

    &__access-button {
        &--disabled {
            pointer-events: none;
            --nebula-button-background-disabled: $nebula-color-platform-interface-400;
            --nebula-button-border-color-disabled: $nebula-color-platform-interface-400;
            --nebula-button-text-color-disabled: $nebula-color-platform-interface-900;
        }
        &--active {
            --nebula-button-text-color: $nebula-color-platform-interface-800;
        }
    }

    &__title {
        p {
            margin-top: $nebula-space-1x;
        }
    }
    &__input {
        margin-block-start: $nebula-space-5x;
        margin-block-end: $nebula-space-5x;
    }

    &__remove-button {
        --nebula-button-text-color: $nebula-color-platform-interface-900;
        // --nebula-button-font-size: 16px;
    }

    &__member-avatar {
        margin-inline-end: $nebula-space-2x;
    }

    &__member-name {
        body-1();
        font-weight: 700;
        margin: 0;
    }

    &__member-title {
        body-1();
        margin: 0;
        line-height: 1em;
    }

    &__name-cell {
        tableCell()
        align-items: center;
        justify-content: flex-start;
        display: flex;
    }

    &__remove-cell {
        tableCell();
        align-items: center;
        justify-content: flex-end;
        display: flex;
    }

    &__table-cell {
        tableCell();
    }

    &__disabled-breadcrumb {
        pointer-events: none;
    }
}
</style>
