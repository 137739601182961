<template>
    <div class="page-wrapper">
        <div class="page-wrapper__container">
            <div
                v-if="$slots.breadcrumbs"
                class="page-wrapper__breadcrumbs"
            >
                <slot name="breadcrumbs" />
            </div>

            <div
                v-if="$slots.heading"
                class="page-wrapper__heading"
            >
                <slot name="heading" />
            </div>

            <div
                v-if="$slots.body"
                class="page-wrapper__body"
            >
                <slot name="body" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageWrapper',
};
</script>
